body {
  background: rgb(42,49,2);
  background: radial-gradient(circle, rgba(42,49,2,1) 0%, rgba(2,2,0,1) 65%);
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.farmCardBackLogo{
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;
}